
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import { Household } from "@/models/households";
  import { CollectionResourceDoc } from '@/models/jsonapi'

  interface Props {
    id: string
  }

  export default defineComponent({
    props: {
      id: {
        type: String,
        required: true
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const dbData = computed<CollectionResourceDoc<string, Household>>(() => root.$store.state.households.element)

      root.$store.dispatch('households/get', { id: props.id })

      return {
        dbData
      }
    }
  })
